import React, { Component } from 'react'
import Callback from './callback'
import LoginLayout from '../layouts/default/loginLayout'
import SCLoginLayout from '../layouts/store-checkout-skin/loginLayout'
import DefaultLogin from './default/login'
import ErrorPage from './errorPage'
import { extractSkinNameFromPath } from '../utils/helpers'
import Forbidden from '../pages/403'
import ForgotPassword from '../pages/forgotPassword'
import Messages from '../constants/messages'
import Onboard from './onboard'
import OnboardLayout from '../layouts/onboard/onboardLayout'
import { Switch } from 'react-router-dom'
import SkinPALogin from '../pages/partner-access-skin/login'
import SkinSRLogin from '../pages/supplier-reports-skin/login'
import SkinSCLogin from '../pages/store-checkout-skin/login'
import withTheme from '../components/withTheme'
import CoreLayout from '../layouts/common/coreLayout';

class Index extends Component {

  renderRoute() {
    const skinValue = extractSkinNameFromPath(window.location.pathname)
    let skin = skinValue !== null ? skinValue[0] : 'default'
    let route

    switch (skin) {
      case 'partner-access-skin':
        route = (<LoginLayout path='/partner-access-skin' component={SkinPALogin} />)
        break;
      case 'supplier-reports-skin':
        route = (<LoginLayout path='/supplier-reports-skin' component={SkinSRLogin} />)
        break;
      case 'store-checkout-skin':
        route = (<SCLoginLayout path='/store-checkout-skin' component={SkinSCLogin} />)
        break;
      case 'selling-pin':
        route = (<SCLoginLayout path='/selling-pin' component={SkinSCLogin} />)
        break;
      default:
        route = (<LoginLayout path='/login' component={DefaultLogin} />)
    }

    return route
  }

  render() {
    return (
      <main>
        <Switch>
          {this.renderRoute()}
          <CoreLayout exact path='/forgot-password'  component={ForgotPassword} applicationTitle={Messages['pageMessages']['FORGOT_PASSWORD_TITLE']}/>
          <LoginLayout exact path='/callback' component={Callback} />
          <OnboardLayout exact path='/onboard' component={Onboard} />
          <OnboardLayout exact path='/error' component={ErrorPage} />
          <CoreLayout exact path='/403' component={Forbidden} />
        </Switch>
      </main>
    )
  }
}

export default withTheme(Index)
