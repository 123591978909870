/* eslint-disable flowtype/require-valid-file-annotation */

import { MuiThemeProvider, withStyles } from 'material-ui/styles'
import React, { Component } from 'react'
import wrapDisplayName from 'recompose/wrapDisplayName'
import DefaultGlobal from '../styles/default/global'
import GlobalOnboard from '../styles/default/globalOnboard'
import DefaultTheme from '../styles/default/theme'
import getContext from '../styles/getContext'
import SCGlobal from '../styles/store-checkout-skin/global'
import SGTheme from '../styles/store-checkout-skin/theme'

let AppWrapper = props => props.children

function withTheme(BaseComponent) {

  class WithTheme extends Component {
    static getInitialProps(ctx) {
      if (BaseComponent.getInitialProps) {
        return BaseComponent.getInitialProps(ctx)
      }

      return {}
    }

    componentWillMount() {
      const skinValue = window.location.pathname.match(/(?:\w+-)*\w*-skin|selling-pin|onboard/i)
      let skin = skinValue !== null ? skinValue[0] : 'default'
      let theme

      switch (skin) {
        case 'partner-access-skin':
        case 'supplier-reports-skin':
          AppWrapper = withStyles(DefaultGlobal)(AppWrapper)
          theme = DefaultTheme
          break
        case 'selling-pin':
        case 'store-checkout-skin':
          AppWrapper = withStyles(SCGlobal)(AppWrapper)
          theme = SGTheme
          break
        case 'onboard':
          AppWrapper = withStyles(GlobalOnboard)(AppWrapper)
          theme = DefaultTheme
          break
        default:
          AppWrapper = withStyles(DefaultGlobal)(AppWrapper)
          theme = DefaultTheme
      }

      this.styleContext = getContext(theme)
    }

    componentDidMount() {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side')
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles)
      }
    }

    render() {
      return (
        <MuiThemeProvider
          theme={this.styleContext.theme}
          sheetsManager={this.styleContext.sheetsManager}>
          <AppWrapper>
            <BaseComponent {...this.props} />
          </AppWrapper>
        </MuiThemeProvider>
      )
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    WithTheme.displayName = wrapDisplayName(BaseComponent, 'withTheme')
  }

  return WithTheme
}

export default withTheme
